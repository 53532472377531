/* eslint-disable no-var */
import React, { useState, useEffect, forwardRef } from 'react'
import { useHistory, useLocation, useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'common/hook-custom'
import { setMenu } from 'features/menu/menuSlice'
import { getData } from 'common/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { imgLogoTet } from 'constant/images'
import { QRCodeCanvas } from 'qrcode.react'
import Dropdown from 'react-bootstrap/Dropdown'
import { SearchNormal1, User, Mobile } from 'iconsax-react'
import icAppStore from '../styles/images/ic_appstore.svg'
import icPlayStore from '../styles/images/ic_playstore.svg'
// import icSearch from '../styles/images/ic_search.png'
// import icMobile from '../styles/images/ic_mobile.png'
// import icUser from '../styles/images/ic_user.png'
import icAccount from '../styles/images/ic_account.png'
// import icDropdown from '../styles/images/ic_dropdown.png'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import icThvliInstall from '../styles/images/ic_thvli_install.webp'
import '../styles/menu-custom.scss'

// eslint-disable-next-line react/display-name, react/prop-types
const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  let isLoginData = false

  const tokenData = localStorage && localStorage.getItem('adm_token')
  if (tokenData) {
    if (tokenData !== '') {
      isLoginData = true
    }
  }

  const emailData = localStorage.getItem('adm_email')
  const emailParse = typeof emailData === 'string' ? `${emailData.substring(0, 7)}...` : ''

  return (
    <div
      className="login"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {/* <img src={icUser} /> */}
      <User color="white" size={17} />
      <span className="login-text">{isLoginData ? emailParse : 'Đăng nhập'}</span>
    </div>
  )
})

// eslint-disable-next-line react/display-name, react/prop-types
const CustomMenu = forwardRef(({ children, style, className }, ref) => {
  return (
    <div ref={ref} style={style} className={className}>
      {children}
    </div>
  )
})

const MenuCustom = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [active, setActive] = useState(0)
  const [isLogin, setIsLogin] = useState(false)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isScroll, setIsScroll] = useState(false)
  const [show, setShow] = useState(false)

  const [dataMenuList, setDataMenuList] = useState([])

  const { data } = useQuery('getMenu', async () => {
    return await getData('GET', 'menu/e3f56e40-94b0-4e1f-9830-7c7f0d1bd354/')
  })

  useEffect(() => {
    const tokenData = localStorage && localStorage.getItem('adm_token')
    if (tokenData) {
      if (tokenData !== '') {
        setIsLogin(true)

        const fetchCheckToken = async () => {
          try {
            // eslint-disable-next-line no-unused-vars
            const resultCheckToken = await axios({
              method: 'GET',
              url: `${process.env.REACT_APP_API_SETTING_URL}profile/`,
              headers: {
                Authorization: `${localStorage.getItem('adm_token')}`
              }
            })
          } catch (err) {
            if (err?.response) {
              const statusCheck = err?.response?.status
              if (statusCheck === 403) {
                try {
                  const resultRefreshToken = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_API_SETTING_URL}refresh/`,
                    headers: {
                      Authorization: `${localStorage.getItem('adm_refresh_token')}`
                    }
                  })

                  if (resultRefreshToken) {
                    const dataRefreshToken = resultRefreshToken.data
                    if (dataRefreshToken) {
                      localStorage.setItem('adm_token', dataRefreshToken.access_token)
                      localStorage.setItem('adm_refresh_token', dataRefreshToken.refresh_token)
                    }
                  }
                } catch (err) {
                  handleClickLogout()
                  console.log(err.response)
                }
              }
            }
          }
        }

        fetchCheckToken()
      }
    }

    window.addEventListener('scroll', (e) => handleScrollNavigation(e))

    return () => { // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener('scroll', (e) => handleScrollNavigation(e))
    }
  }, [])

  const handleScrollNavigation = () => {
    if (window.scrollY > 10) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  }

  useEffect(() => {
    if (Array.isArray(data)) {
      const dataTemp = [...data]
      // dataTemp.unshift({

      // Euro
      // dataTemp.splice(2, 0, {
      //   icon: '',
      //   id: process.env.REACT_APP_MENU_EURO_ID,
      //   name: 'Euro 2024',
      //   required: false,
      //   slug: 'euro-2024-web',
      //   sub_menu: []
      // })

      dataTemp.splice(3, 0, {
        icon: '',
        id: process.env.REACT_APP_MENU_HOT_ID,
        name: 'Mới & Hot',
        required: false,
        slug: 'moi-va-hot-web',
        sub_menu: []
      })
      setDataMenuList(dataTemp)

      if (Array.isArray(dataTemp) && dataTemp.length > 0) {
        const locationPath = location.pathname.substr(0, 5)
        if (locationPath === '/page') {
          const indexFind = dataTemp.findIndex((item) => {
            return item.slug === slug
          })
          setActive(indexFind)
          dispatch(setMenu(dataTemp[indexFind]))
        } else if (locationPath === '/live') {
          const indexFind = dataTemp.findIndex((item) => {
            return item.id === '3971c587-2ed1-4b6b-ac09-4eb82de344a9' || item.name === 'TRỰC TUYẾN' || item.slug === 'truc-tuyen-zua0j'
          })
          setActive(indexFind)
          dispatch(setMenu(dataTemp[indexFind]))
        } else if (locationPath === '/detail') {
          setActive(-1)
        } else {
          dispatch(setMenu(dataTemp[0]))
          // dispatch(setMenu(dataTemp[1]))
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (show) {
      const modalOpen = document.getElementById('modalDownloadOpen')
      if (modalOpen) {
        modalOpen.click()
      }
    }
  }, [show])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickMenu = (e, index) => {
    if (dataMenuList[index] && (dataMenuList[index].id === '303439c8-8869-4489-a753-6177c58765cb' ||
      dataMenuList[index].name === 'TRANG CHỦ' || dataMenuList[index].slug === 'trang-chu')) {
      history.push('/')
    } else if (dataMenuList[index] && (dataMenuList[index].id === '3971c587-2ed1-4b6b-ac09-4eb82de344a9' ||
      dataMenuList[index].name === 'TRỰC TUYẾN' || dataMenuList[index].slug === 'truc-tuyen-zua0j')) {
      history.push('/live/thvl1-hd')
    } else {
      setActive(index)
      dispatch(setMenu(dataMenuList[index]))
      history.push(`/page/${dataMenuList[index].slug}`)
    }
  }

  const handleClickLogout = (e) => {
    if (e) {
      e.preventDefault()
    }

    // Check login Facebook
    // eslint-disable-next-line no-undef
    if (FB) {
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          // eslint-disable-next-line no-undef
          FB.logout(responseLogout => {
            // user is now logged out
          })
        }
      })
    }

    // eslint-disable-next-line no-undef
    if (gapi) {
      // eslint-disable-next-line no-undef
      var auth2 = gapi.auth2 && gapi.auth2.getAuthInstance()
      if (auth2) {
        auth2.signOut().then(function () {
          console.log('User signed out.')
        })
      }
    }

    localStorage.removeItem('adm_email')
    localStorage.removeItem('adm_token')
    localStorage.removeItem('adm_fb_token')
    localStorage.removeItem('adm_avatar')

    if (window.location.pathname === '/tv') {
      window.location.href = '/'
    } else {
      window.location.reload()
    }
  }

  const handleOpenModal = () => {
    setShow(true)
  }

  const handleCloseModal = () => {
    setShow(false)
  }

  const handleClickAppStore = () => {
    window.location.href = 'https://apps.apple.com/us/app/thvli/id1185316310'
  }

  const handleClickGoogleApp = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
  }

  const handleToggleDropdown = (e, toggleMeta) => {
    if (toggleMeta) {
      if (toggleMeta.source === 'click') {
        setIsShowDropdown(true)
      }
      if (toggleMeta.source === 'rootClose') {
        setIsShowDropdown(false)
      }
    }
  }

  const handleClickOpen = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      let appstoreURL = ''

      if (navigator.userAgent.match(/Android/i)) {
        appstoreURL = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
      }

      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        appstoreURL = 'itms-apps://itunes.apple.com/app/thvli/id1185316310'
      }

      const deeplinkPath = 'thvli://'

      const now = new Date().valueOf()
      setTimeout(function () {
        if (new Date().valueOf() - now > 100) {
          window.location = appstoreURL
        }
      }, 2000)

      window.location = deeplinkPath
    }
  }

  return (
    <>
      {isDesktop
        ? <>
            <nav className={`adm-menu navbar navbar-dark navbar-expand-lg ${isScroll ? 'scrollMenu' : ''}`} id="adm-menu">
              <div className="container-fluid">
                <button className="navbar-toggler adm-navbar-toggler adm-offshadow" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                  <span className="d-flex align-items-center navbar-toggler-icon">
                    <FontAwesomeIcon icon={faBars} size="1x" color="white" />
                  </span>
                </button>

                <Link to="/">
                  {/* <img className="navbar-brand ms-2 me-4 menu-logo" src={imgLogo} /> */}
                  <img className="navbar-brand menu-logo" src={imgLogoTet} />
                </Link>

                <div className="navbar-collapse">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {Array.isArray(dataMenuList) && dataMenuList.map((item, index) => {
                      if (item.id === '2d3617eb-cc03-4486-8f3c-8513e7eb44dc' || item.slug === 'tv-show') {
                        return (
                          <li key={index} className={`nav-item bottom adm-nav-item ${index === active ? 'active' : ''}`} onClick={e => handleClickMenu(e, index)}>
                            <a className={`nav-link nav-link-custom-special ${index === active ? 'active' : ''} `} aria-current="page">TV Show</a>
                          </li>
                        )
                      } else {
                        return (
                          <li key={index} className={`nav-item bottom adm-nav-item ${index === active ? 'active' : ''}`} onClick={e => handleClickMenu(e, index)}>
                            <a className={`nav-link nav-link-custom adm-nav-link ${index === active ? 'active' : ''} `} aria-current="page">{item.name}</a>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </div>

                <div className='search-container'>
                  <div className="search">
                    {/* <FontAwesomeIcon className="search-icon" icon={faSearch} /> */}
                    <Link to="/search">
                      {/* <img src={icSearch} /> */}
                      <SearchNormal1 color="white" size={17} />
                    </Link>
                  </div>
                </div>

                <div className='download-container'>
                  <div className="appDownload" onClick={handleOpenModal}>
                    {/* <FontAwesomeIcon className="search-icon" icon={faMobileAlt} /> */}
                    {/* <img src={icMobile} /> */}
                    <Mobile color="white" size={17} />
                  </div>
                </div>

                <div className='user-profile-container'>
                  <Dropdown show={isShowDropdown} onToggle={handleToggleDropdown}>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="auth-dropdown"
                    ></Dropdown.Toggle>

                    {isLogin
                      ? <>
                          <Dropdown.Menu as={CustomMenu} className="menuAuth">
                            <div className="profileContainer">
                              <img src={icAccount} width={24} height={24} alt="auth" />

                              <div className="userEmail">
                                {localStorage.getItem('adm_email')}
                              </div>
                            </div>

                            <ul className="listProfile">
                              <li><Link to="/profile">Thông tin tài khoản</Link></li>
                              <li><Link to="/watching">Nội dung yêu thích</Link></li>
                              {/* <li><Link to="/change-password">Đổi mật khẩu</Link></li> */}
                            </ul>

                            <div className="dividerGt">
                              <hr />
                            </div>

                            <div className="btnLogoutGt" onClick={handleClickLogout}>
                              <button>Đăng xuất</button>
                            </div>
                          </Dropdown.Menu>
                        </>
                      : <Dropdown.Menu as={CustomMenu} className="menuAuth">
                          <div className="signupGt">
                            Đăng ký miễn phí để tận hưởng
                          </div>
                          <ul className="listGt">
                            <li>Tiếp tục xem nội dung trên thiết bị khác</li>
                            <li>Lưu nội dung yêu thích</li>
                            <li>Nội dung đề xuất được cá nhân hóa</li>
                          </ul>

                          <div className="btnSignupGt">
                            <Link to="/register">
                              <button>Đăng Ký</button>
                            </Link>
                          </div>

                          <div className="dividerGt">
                            <hr />
                          </div>

                          <div className="loginGt">
                            Đã có tài khoản?{''}
                            <span>
                              <Link className="login-link" to="/login">Đăng nhập</Link>
                            </span>
                          </div>
                        </Dropdown.Menu>
                    }
                  </Dropdown>
                </div>
              </div>
            </nav>
          </>
        : <div className='mobile-menu-custom'>
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
              <>
                {navigator.userAgent.match(/iPhone|iPad|iPod/i)
                  ? <></>
                  : <>
                      {(location.pathname === '/') &&
                        <div className='mobile-install-app'>
                          <div className='mobile-install-app-info'>
                            <div className='mobile-install-app-info-img'>
                              <img src={icThvliInstall} width={35} height={35} alt="" />
                            </div>
                            <div>
                              <div className='mobile-install-app-thvli'>THVLi</div>
                              <div className='mobile-install-app-open-in'>Open in the THVLi app</div>
                            </div>
                          </div>
                          <div className='mobile-install-app-open' onClick={handleClickOpen}>OPEN</div>
                        </div>
                      }
                    </>
                }
              </>
            }

            <div className='menu-mobile-container-menu'>
              <div className='menu-mobile-brand-action'>
                <div className='menu-mobile-brand-action-logo'>
                  <Link className="navbar-brand" to="/">
                    {/* <img src={imgLogo} className="" height="25" /> */}
                    <img src={imgLogoTet} className="" height="35" />
                  </Link>
                </div>

                <div className='menu-mobile-brand-action-info'>
                  <Link className="search me-2" to="/search">
                    <SearchNormal1 color="white" size={20} />
                    {/* <FontAwesomeIcon className="search-icon" icon={faSearch} /> */}
                  </Link>

                  <Link className="appDownload" to="/download-app">
                    <Mobile color="white" size={20} />
                    {/* <img src={icMobile} width={20} height={20} /> */}
                    {/* <FontAwesomeIcon className="search-icon" icon={faMobileAlt} /> */}
                  </Link>

                  {isLogin
                    ? <div className="dropdown">
                        {/* {avatar
                          ? <img src={avatar} data-bs-toggle="dropdown" aria-expanded="false" className="adm-avatar" />
                          : <FontAwesomeIcon className="login-icon" data-bs-toggle="dropdown" aria-expanded="false" icon={faUserCircle} />
                        } */}
                        <div data-bs-toggle="dropdown" aria-expanded="false">
                          <User color="white" size={20} />
                        </div>
                        {/* <img className="login-icon" width={20} height={20} data-bs-toggle="dropdown" aria-expanded="false" src={icUser} /> */}
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                          <li><Link className="dropdown-item" to="/profile">Thông tin</Link></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><a className="dropdown-item" href="#" onClick={handleClickLogout}>Đăng xuất</a></li>
                        </ul>
                      </div>
                    : <Link className="login" to="/login">
                        <User color="white" size={20} />
                        {/* <FontAwesomeIcon className="login-icon" icon={faUserCircle} /> */}
                      </Link>
                    }
                </div>
              </div>

              <ul className="menu-mobile-list">
                {Array.isArray(dataMenuList) && dataMenuList.map((item, index) => {
                  if (item.id === '2d3617eb-cc03-4486-8f3c-8513e7eb44dc' || item.slug === 'tv-show') {
                    return (
                      <li key={index} className={`item-mobile-menu-special ${index === active ? 'active' : ''}`} onClick={e => handleClickMenu(e, index)}>
                        TV Show
                      </li>
                    )
                  } else {
                    return (
                      <li key={index} className={`item-mobile-menu ${index === active ? 'active' : ''}`} onClick={e => handleClickMenu(e, index)}>
                        {item.name}
                      </li>
                    )
                  }
                })}

                <hr className="divider" />

                <li>
                  <Link className="free-text" to="/faq">
                    <span>Điều khoản dịch vụ</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
      }

      <div className="modal-download">
        <div className="modal fade" id="modalDownloadBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button className="btn btn-dark btn-close-custom" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className="download">TẢI ỨNG DỤNG THVLi</div>
                <div className="scan-qrcode">Quét mã QR Code bên dưới</div>

                <div className="qrcode-data">
                  <QRCodeCanvas
                    value="https://thvli.vn/download-app"
                    size={185}
                    includeMargin
                  />
                </div>

                <div className="or">hoặc</div>
                <div className="copy">Sao chép đường dẫn và gửi đến điện thoại của bạn</div>
                <div className="link-copy">https://thvli.vn/download-app</div>

                <div className="download-icon">
                  <img src={icAppStore} onClick={handleClickAppStore} />
                  <img className="icon-google" src={icPlayStore} onClick={handleClickGoogleApp} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button id="modalDownloadOpen" style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#modalDownloadBackdrop">
          Open
        </button>
      </div>
    </>
  )
}

export default MenuCustom
