import React from 'react'
import { Link } from 'react-router-dom'
import { imgLogoTet } from 'constant/images'
// import GoogleMapReact from 'google-map-react'
import { QRCodeCanvas } from 'qrcode.react'
import packageJson from '../../../../package.json'
import icAppStore from '../styles/images/ic_appstore.svg'
import icPlayStore from '../styles/images/ic_playstore.svg'
import icFacebook from '../styles/images/ic_facebook.png'
import icYoutube from '../styles/images/ic_youtube.png'
import icTikTok from '../styles/images/ic_tiktok.png'
import '../styles/footer.scss'

// eslint-disable-next-line react/prop-types
// const AnyReactComponent = ({ text }) => <div>{text}</div>

const Footer = (props) => {
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627
  //   },
  //   zoom: 11
  // }

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickAppStore = () => {
    window.location.href = 'https://apps.apple.com/us/app/thvli/id1185316310'
  }

  const handleClickGoogleApp = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
  }

  const handleClickFacebook = () => {
    // window.location.href = 'https://www.facebook.com/THVLi.Offical/'
    window.open('https://www.facebook.com/THVLi.Official/', '_blank')
  }

  const handleClickYoutube = () => {
    // window.location.href = 'https://youtube.com/VinhLongTV'
    window.open('https://youtube.com/VinhLongTV', '_blank')
  }

  const handleClickTikTok = () => {
    // window.location.href = 'https://www.tiktok.com/@thvlgiaitri'
    window.open('https://www.tiktok.com/@thvli.official', '_blank')
  }

  return (
    <div className="footer card">
      <div className="card-body">
        {/* <div className="row">
          <hr className="divider-footer" />
        </div> */}

        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="logo-footer">
              <img className="pt-md-1 img-footer-logo" src={imgLogoTet} />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="logo-footer">
              <div className="title-footer">QUY ĐỊNH</div>
              {/* <div className='section-footer'>
                <Link to="/support">
                  Điều Khoản Sử Dụng
                </Link>
              </div> */}
              <div className='section-footer'>
                <Link to="/privacy-policy">
                  Chính Sách Quyền Riêng Tư
                </Link>
              </div>
              <div className='section-footer'>
                <img src={icFacebook} width={26} height={26} onClick={handleClickFacebook} />
                <img src={icYoutube} width={30} height={30} className="ms-3" onClick={handleClickYoutube} />
                <img src={icTikTok} width={26} height={26} className="ms-3" onClick={handleClickTikTok} />
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="logo-footer">
              <div className="title-footer">TRỢ GIÚP</div>
              <div className='section-footer'>
                <Link to="/support">
                  Trung Tâm Hỗ Trợ
                </Link>
              </div>
              <div className='section-footer'>
                <Link to="/faq">
                  FAQ
                </Link>
              </div>
              {/* <div className='section-footer'>
                <Link to="/faq">
                  Liên Hệ
                </Link>
              </div>
              <div className='section-footer'>
                <Link to="/faq">
                  Góp Ý
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <hr className="divider-footer" />
        </div>

        <div className="row">
          <div className="col-12 col-lg-4 col-qrcode-mobile">
            <div className="qr-container">
              <div className="qrcode-data">
                <QRCodeCanvas
                  value="https://thvli.vn/download-app"
                  size={67}
                  includeMargin
                />
              </div>

              <div className="scan-qr-code-container">
                <div className="scan-qr-code">Quét mã QR để tải ứng dụng</div>
                <div className="download-icon">
                  <img src={icAppStore} onClick={handleClickAppStore} />
                  <img className="icon-google" src={icPlayStore} onClick={handleClickGoogleApp} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-8">
            <div className="info-footer">
              <div className="footer-company pt-4 pt-lg-2">Đài Phát thanh và Truyền hình Vĩnh Long</div>
              <div className="footer-license">Giấy phép Cung cấp dịch vụ Phát thanh, Truyền hình trả tiền số 166/GP-BTTTT cấp ngày 26/4/2019.</div>
              <div className="footer-license adm-address">Địa chỉ: 50 Phạm Thái Bường, Phường 4, TP. Vĩnh Long, tỉnh Vĩnh Long</div>
              <div>
                <span className="footer-version">
                  {' '}(v{packageJson.version})
                </span>
              </div>
              {/* <div className="footer-version">v{packageJson.version}</div> */}
            </div>
          </div>
          <div className="col-12 col-lg-4 col-qrcode-desktop">
            <div className="qr-container">
              <div className="qrcode-data">
                <QRCodeCanvas
                  value="https://thvli.vn/download-app"
                  size={67}
                  includeMargin
                />
              </div>

              <div className="scan-qr-code-container">
                <div className="scan-qr-code">Quét mã QR để tải ứng dụng</div>
                <div className="download-icon">
                  <img src={icAppStore} onClick={handleClickAppStore} />
                  <img className="icon-google" src={icPlayStore} onClick={handleClickGoogleApp} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
