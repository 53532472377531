import packageJson from '../../package.json'

export const trackingPlayer = (detail, progress) => {
  const path = 'https://tracking-srv.stg-env.admon.com.vn/tracking'
  const ua = window.navigator ? window.navigator.userAgent : 'ua'
  const dataPost = {
    // content: detail ? detail.id : null,
    content: detail?.type === 5 ? detail?.id : (detail?.default_episode?.id || detail?.id || null),
    platform: 'web',
    user_agent: ua,
    version: `v${packageJson.version}`,
    // duration: progress,
    duration: 15,
    show: detail?.default_episode?.parent_episode?.show_id ? detail?.default_episode?.parent_episode?.show_id : null,
    type: detail.type,
    season: detail?.default_episode?.parent_episode?.season_id ? detail?.default_episode?.parent_episode?.season_id : null,
    title: detail.title,
    slug: detail.slug
    // progress: progress
  }

  fetch(path, {
    method: 'POST',
    body: JSON.stringify(dataPost)
  })
    .then((response) => {
      //
    })
    .catch(error => {
      console.log(error)
    })
}
