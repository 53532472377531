import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { getData, getDataDetail, getDataServerTime, getAgeContentRating, callApiAds } from 'common/utils'
import { sentryException } from 'common/sentry'
import { trackingPlayer } from 'common/tracking'
import XMLWriter from 'xml-writer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faStepForward } from '@fortawesome/free-solid-svg-icons'
import { imgholder } from 'constant/images'
import { setProgress } from 'features/watching/watchingSlice'
import Overlay from 'components/Overlay'
import ModalReportError from 'features/core/ModalReportError'
import Video from 'components/Video'
import DetailInfo from './DetailInfo'
import DetailSeason from './DetailSeason'
import DetailRelate from './DetailRelate'
import DetailAds from './DetailAds'
import AdsItem from 'components/AdsItem'
import packageJson from '../../../../package.json'
import { v4 as uuidv4Package } from 'uuid'
import canAutoplay from 'can-autoplay'
import 'bootstrap/js/dist/tab'
import '../styles/detail.scss'

const Detail = (props) => {
  // const history = useHistory()
  const { page, ribbon, slug } = useParams()
  const dispatch = useDispatch()
  const ref = useRef(null)
  const trackingRef = useRef(null)

  const progress = useSelector((state) => state.watching.progress)

  // Trạng thái hiển thị skip intro
  const [showSkipIntro, setShowSkipIntro] = useState(false)

  const [ageRating, setAgeRating] = useState(null)

  const [options, setOptions] = useState(null)
  const [src, setSrc] = useState(null)
  const [player, setPlayer] = useState(null)
  const [seasonId, setSeasonId] = useState(null)
  const [title, setTitle] = useState('')
  const [adsGenre, setAdsGenre] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [seasonList, setSeasonList] = useState([])
  const [episodeList, setEpisodeList] = useState([])
  const [relateList, setRelateList] = useState([])
  const [hashtagList, setHastagList] = useState([])
  // const [intervalProgress, setIntervalProgress] = useState(null)
  const [intervalSkipIntro, setIntervalSkipIntro] = useState(null)

  const [isShowModal, setIsShowModal] = useState(false)

  // Countdown Ads
  const [isEndVod, setIsEndVod] = useState(false)
  const [counterNextVod, setCounterNextVod] = useState(18)
  const [intervalNextVod, setIntervalNextVod] = useState(null)

  // Next Episode
  const [isNextEpisode, setIsNextEpisode] = useState(false)
  const [titleNextEpisode, setTitleNextEpisode] = useState('')
  const [idNextEpisode, setIdNextEpisode] = useState(null)
  const [counterNextEpisode, setCounterNextEpisode] = useState(10)
  const [intervalNextEpisode, setIntervalNextEpisode] = useState(null)

  // Skip Ads
  const [adsManager, setAdsManager] = useState(null)
  // const [timeoutIsAdPlaying, setTimeoutIsAdPlaying] = useState(null)
  const [isHoverSkipAd, setIsHoverSkipAd] = useState(false)
  const [isAdPlaying, setIsAdPlaying] = useState(false)
  const [isCountering, setIsCountering] = useState(false)
  const [isSkipableAds, setIsSkipableAds] = useState(false)
  const [counterSkipAds, setCounterSkipAds] = useState(7)

  const [isLoading, setIsLoading] = useState(false)

  const { data, isError, error, refetch } = useQuery(['getDetail', slug], async () => {
    return await getDataDetail('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
  }, {
    retry: false,
    cacheTime: 0,
    staleTime: 0
  })

  // check next episode
  const { data: dataSeasonInfo } = useQuery(['getSeasonInfo', seasonId], async () => {
    if (seasonId) {
      // return await getData('GET', `season_by_id/${group}`)
      return await getData('GET', `get_season_by_id/${seasonId}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
    }
  })

  const eventListener = useCallback(() => {
  }, [data, dataSeasonInfo, relateList, player, intervalNextVod, intervalNextEpisode])

  useEffect(() => {
    window.onpopstate = e => {
      window.location.reload()
    }

    setIsLoading(true)

    return () => {
      if (trackingRef.current) {
        clearInterval(trackingRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isError && error) {
      if (error?.request?.status === 403) {
        const fetchDataServerTime = async () => {
          const resultServerTime = await getDataServerTime()
          if (resultServerTime) {
            const currentTimestamp = Date.now()
            const timeDiff = currentTimestamp - resultServerTime
            localStorage.setItem('TIME_NOW', currentTimestamp)
            localStorage.setItem('TIME_SERVER', resultServerTime)
            localStorage.setItem('TIME_DIFF', timeDiff)
          }

          setTimeout(() => {
            refetch()
          }, 200)
        }

        fetchDataServerTime()
      }
    }
  }, [isError, error])

  const handleEventPlayer = () => {
    setIsEndVod(false)
    setIsNextEpisode(false)

    if (intervalNextVod) {
      setCounterNextVod(18)
      clearInterval(intervalNextVod)
    }
    if (intervalNextEpisode) {
      setCounterNextEpisode(10)
      clearInterval(intervalNextEpisode)
    }
  }

  useEffect(() => {
    if (player) {
      player.on('play', () => {
        handleEventPlayer()
      })
      player.on('pause', () => {
        const overlayAds = document.getElementById('video-overlay-ads')

        if (!player.seeking() && player.paused()) {
          if (overlayAds) {
            overlayAds.style.display = 'block'
          }
        }
      })
      player.on('playing', function () {
        const overlayAds = document.getElementById('video-overlay-ads')
        if (overlayAds) {
          overlayAds.style.display = 'none'
        }
      })
      player.on('seeking', () => {
        handleEventPlayer()
      })
      player.on('ads-ad-started', () => {
        handleEventPlayer()
      })
      player.on('adskip', () => {
        handleEventPlayer()

        // Skip Ads
        setIsAdPlaying(false)
        setIsCountering(false)
      })
      player.on('adend', () => {
        handleEventPlayer()

        // Skip Ads
        setIsAdPlaying(false)
        setIsCountering(false)
      })
      player.on('adtimeout', (event) => {
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      player.on('adserror', (event) => {
        setIsAdPlaying(false)
        setIsCountering(false)
        // player.play()
      })

      // player.on('adskip', (event) => {
      //   console.log('adskip', event)
      //   if (!isEndVod && !isNextEpisode) {
      //     player.play()
      //   }
      // })

      // player.on('adend', (event) => {
      //   console.log('adend', event)
      //   if (!isEndVod && !isNextEpisode) {
      //     player.play()
      //   }
      // })

      // player.on('adtimeout', (event) => {
      //   console.log('adtimeout', event)
      //   if (!isEndVod && !isNextEpisode) {
      //     player.play()
      //   }
      // })

      // player.on('adserror', (event) => {
      //   console.log('adserror', event)
      //   if (!isEndVod && !isNextEpisode) {
      //     player.play()
      //   }
      // })

      player.on('ads-ad-started', () => {
        const overlayAds = document.getElementById('video-overlay-ads')
        if (overlayAds) {
          overlayAds.style.display = 'none'
        }

        setIsAdPlaying(true)
        setIsCountering(true)
        setCounterSkipAds(7)
      })

      player.on('ads-manager', (response) => {
        const adsManager = response.adsManager
        setAdsManager(adsManager)
        setIsSkipableAds(adsManager.getAdSkippableState())
      })

      player.on('ended', () => {
        console.log(episodeList)

        // End VOD
        if (data && Array.isArray(relateList)) {
          if (relateList.length > 0) {
            if (data.type === 1) { // Single Movie
              setIsEndVod(true)
            } else if (data.type === 2 || data.type === 4) { // Show/Drama, Episode
              const listEpisode = dataSeasonInfo ? dataSeasonInfo.episodes : []
              let defaultEpisodeId = null

              if (data.type === 2) {
                defaultEpisodeId = data.default_episode && data.default_episode.id
              } else {
                defaultEpisodeId = data.id
              }
              let isNextEpisode = false

              if (Array.isArray(listEpisode)) {
                for (let i = 0; i < listEpisode.length; i++) {
                  if (listEpisode[i].id === defaultEpisodeId) {
                    if (listEpisode[i + 1]) {
                      isNextEpisode = true
                    }
                  }
                }
              }

              if (isNextEpisode) {
                setIsEndVod(false)
              } else {
                setIsEndVod(true)
              }
            } else {
              setIsEndVod(false)
            }
          } else {
            setIsEndVod(false)
          }
        }

        // Next Episode
        if (data.type === 2 || data.type === 4) {
          const listEpisode = dataSeasonInfo ? dataSeasonInfo.episodes : []
          let defaultEpisodeId = null

          if (data.type === 2) {
            defaultEpisodeId = data.default_episode && data.default_episode.id
          } else {
            defaultEpisodeId = data.id
          }
          let isNextEpisode = false

          if (Array.isArray(listEpisode)) {
            for (let i = 0; i < listEpisode.length; i++) {
              if (listEpisode[i].id === defaultEpisodeId) {
                if (listEpisode[i + 1]) {
                  setTitleNextEpisode(listEpisode[i + 1].title)
                  setIdNextEpisode(listEpisode[i + 1].id)
                  isNextEpisode = true
                }
              }
            }
          }

          if (isNextEpisode) {
            setIsNextEpisode(true)
          } else {
            setIsNextEpisode(false)
          }
        }
      })
    }
  }, [eventListener])

  useEffect(() => {
    if (isAdPlaying) {
      if (counterSkipAds === 0) {
        setIsCountering(false)
      }

      const timer =
        counterSkipAds > 0 && setInterval(() => setCounterSkipAds(counterSkipAds - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counterSkipAds, isAdPlaying])

  useEffect(() => {
    if (isEndVod) {
      if (counterNextVod === 0) {
        if (Array.isArray(relateList) && relateList.length > 0) {
          const itemFirst = relateList[0]
          if (itemFirst) {
            window.location.href = `/detail/${itemFirst.slug}`
          }

          // const arrFirst = relateList[0]
          // if (Array.isArray(arrFirst) && arrFirst.length > 0) {
          //   const itemFirst = arrFirst[0]
          //   // history.push(`/detail/${itemFirst.slug}`)

          //   // history.push(`/detail/${itemFirst.slug}`)
          //   // history.push('/empty')
          //   // history.goBack()
          //   window.location.href = `/detail/${itemFirst.slug}`
          // }
        }
      }

      const timer =
        counterNextVod > 0 && setInterval(() => setCounterNextVod(counterNextVod - 1), 1000)
      setIntervalNextVod(timer)
      return () => clearInterval(timer)
    }
  }, [counterNextVod, isEndVod])

  useEffect(() => {
    if (isNextEpisode) {
      if (counterNextEpisode === 0) {
        // history.push(`/detail/${idNextEpisode}`)

        // history.push(`/detail/${idNextEpisode}`)
        // history.push('/empty')
        // history.goBack()

        window.location.href = `/detail/${idNextEpisode}`
      }

      const timer =
        counterNextEpisode > 0 && setInterval(() => setCounterNextEpisode(counterNextEpisode - 1), 1000)
      setIntervalNextEpisode(timer)
      return () => clearInterval(timer)
    }
  }, [counterNextEpisode, isNextEpisode])

  useEffect(() => {
    return () => {
      if (intervalNextVod) {
        clearInterval(intervalNextVod)
      }
    }
  }, [intervalNextVod])

  useEffect(() => {
    return () => {
      if (intervalNextEpisode) {
        clearInterval(intervalNextEpisode)
      }
    }
  }, [intervalNextEpisode])

  // useEffect(() => {
  //   return () => {
  //     if (intervalProgress) {
  //       clearInterval(intervalProgress)
  //     }
  //   }
  // }, [intervalProgress])

  useEffect(() => {
    return () => {
      if (intervalSkipIntro) {
        clearInterval(intervalSkipIntro)
      }
    }
  }, [intervalSkipIntro])

  const handleSetOptions = (autoplayParam, mutedParam) => {
    setOptions({
      autoplay: autoplayParam,
      muted: mutedParam,
      loop: false,
      controls: true,
      responsive: true,
      fluid: true,
      inactivityTimeout: 4000,
      controlBar: {
        volumePanel: {
          inline: false
        }
      },
      html5: {
        hls: {
          limitRenditionByPlayerDimensions: false
        }
      }
    })
  }

  useEffect(() => {
    if (data) {
      const dataHashtagTemp = data.hashtags
      if (typeof dataHashtagTemp === 'string') {
        if (dataHashtagTemp !== '') {
          const dataHashtagArr = dataHashtagTemp.split(',')
          setHastagList(dataHashtagArr)
        }
      }

      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
          page_title: data.title,
          page_location: page ? `https://thvli.vn/detail/${page}/${ribbon}/${slug}` : `https://thvli.vn/detail/${slug}`,
          page_path: page ? `/${page}/${ribbon}/${slug}` : `/detail/${slug}`,
          send_to: 'G-RW9WHCKH4J'
        })
      }

      if (data.type === 6) {
        document.title = data.episode_name
        setSeasonId(data.group)
      } else {
        document.title = data.title

        const parentEpisode = data.parent_episode
        if (parentEpisode && parentEpisode.season_id) {
          setSeasonId(parentEpisode.season_id)
        } else {
          setSeasonId(data.group)
        }
      }

      setAdsGenre(data.ads_genre)

      let linkPlayDetail = ''

      if (data.type === 6) {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.episode_name)
          linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        } else {
          setTitle(data.display_name ? data.display_name : data.episode_name)
          linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
        }
      } else {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.default_episode.title)
          linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        } else {
          setTitle(data.display_name ? data.display_name : data.title)
          linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
        }
      }

      if (player) {
        if (linkPlayDetail && linkPlayDetail !== '') {
          setSrc({
            type: 'application/x-mpegURL',
            src: linkPlayDetail
          })
        }
      } else {
        canAutoplay
          .video({ timeout: 400, muted: false })
          .then(function (response) {
            if (response.result === false) {
              handleSetOptions(false, false)
            } else {
              // Unmuted autoplay is allowed.
              handleSetOptions(true, false)
            }
          })
      }

      // if (!options) {
      //   setOptions({
      //     autoplay: true,
      //     // muted: true,
      //     loop: false,
      //     controls: true,
      //     responsive: true,
      //     fluid: true,
      //     inactivityTimeout: 4000,
      //     controlBar: {
      //       volumePanel: {
      //         inline: false
      //       }
      //     }
      //     // sources: [{
      //     //   type: 'application/x-mpegURL',
      //     //   src: linkPlay
      //     // }]
      //   })
      // }

      // const urlData = window.location.href
      // const ipResult = await axios({
      //   method: 'GET',
      //   url: 'https://api.ipify.org?format=json'
      // })
      // const ipData = ipResult.data && ipResult.data.ip
      // setAdTagUrl(`https://tv.ads.spotx.tv/vast/650477?w=640&h=480&url=${urlData}&ip=${ipData}`)

      setIsLoading(false)

      let timeInterval = 0
      trackingPlayer(data, timeInterval)

      trackingRef.current = setInterval(() => {
        timeInterval += 15

        trackingPlayer(data, timeInterval)
      }, 15000)

      // Tracking event player
      // trackingPlayer(data)
      //   .then((response) => {
      //     console.log(response)

      //     // Sau khi hoàn tất sự kiện live started
      //     // Gọi api check progress theo các mốc thời gian
      //     let timeInterval = 0
      //     setIntervalProgress(setInterval(() => {
      //       timeInterval += 1

      //       if (timeInterval === 6 || timeInterval === 30 || timeInterval === 60 || timeInterval === 300 ||
      //         timeInterval === 600 || timeInterval === 1200 || timeInterval === 1800 ||
      //         timeInterval === 3600 || timeInterval === 7200) {
      //         trackingPlayer(data, timeInterval.toString())
      //           .then((response) => {
      //             console.log(response)
      //           })
      //           .catch((error) => {
      //             console.log(error)
      //             // sentryException(`[VOD] - ${title}`, {
      //             //   link_play: player && player.currentSrc(),
      //             //   error: error
      //             // })
      //           })
      //       }
      //     }, 1000))
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     // sentryException(`[VOD] - ${title}`, {
      //     //   link_play: player && player.currentSrc(),
      //     //   error: error
      //     // })
      //   })

      if (data?.content_ratings?.length > 0) {
        const ageRatingTemp = getAgeContentRating(
          data?.content_ratings
        )
        setAgeRating(ageRatingTemp)
      }
    }
  }, [data])

  const handleLinkPlayWithTrailer = () => {
    let linkPlayDetail = ''

    if (data.type === 6) {
      if (data.default_episode && data.default_episode.id) {
        setTitle(data.display_name ? data.display_name : data.episode_name)
        linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        setSrc({
          type: 'application/x-mpegURL',
          src: linkPlayDetail
        })
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          setTitle(data.display_name ? data.display_name : data.episode_name)
          linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
          setSrc({
            type: 'application/x-mpegURL',
            src: linkPlayDetail
          })
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  setTitle(resultTrailer.display_name ? resultTrailer.display_name : resultTrailer.episode_name)
                  linkPlayDetail = resultTrailer.play_info && resultTrailer.play_info.data && resultTrailer.play_info.data.hls_link_play
                  setSrc({
                    type: 'application/x-mpegURL',
                    src: linkPlayDetail
                  })
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    } else {
      if (data.default_episode && data.default_episode.id) {
        setTitle(data.display_name ? data.display_name : data.default_episode.title)
        linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        setSrc({
          type: 'application/x-mpegURL',
          src: linkPlayDetail
        })
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          setTitle(data.display_name ? data.display_name : data.title)
          linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
          setSrc({
            type: 'application/x-mpegURL',
            src: linkPlayDetail
          })
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  setTitle(resultTrailer.display_name ? resultTrailer.display_name : resultTrailer.episode_name)
                  linkPlayDetail = resultTrailer.play_info && resultTrailer.play_info.data && resultTrailer.play_info.data.hls_link_play
                  setSrc({
                    type: 'application/x-mpegURL',
                    src: linkPlayDetail
                  })
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    }
  }

  const handleParamAdtagUrlLinkPlay = (linkPlayParam, metadataParam) => {
    let uuidv4Str = ''

    if (localStorage.getItem('adm_uuid')) {
      uuidv4Str = localStorage.getItem('adm_uuid')
    } else {
      const uuidv4 = uuidv4Package()
      localStorage.setItem('adm_uuid', uuidv4)
    }

    let paramStrTemp = `did=${uuidv4Str}\
&deviceid=${uuidv4Str}\
&bundleid=website.thvli.vn\
&vid=${slug}\
&app_name=THVLi\
&ua=${encodeURI(navigator.userAgent)}\
&content_id=${slug}\
&content_url=${window.location.href}\
&content_title=${encodeURI(title)}\
&content_episode=${encodeURI(title)}\
&content_genre=${encodeURI(adsGenre)}\
&content_series=${encodeURI(title)}\
&description_url=${encodeURI(title)}\
&url=${window.location.href}\
&sid=${uuidv4Str}\
&app_version=${packageJson?.version}\
&u=${linkPlayParam}`

    if (metadataParam?.custom_params_str) {
      paramStrTemp += `&cust_params=${metadataParam?.custom_params_str}`
    }

    return paramStrTemp
  }

  const handleParamAdtagUrl = () => {
    let adtagParamStr = ''

    if (data.type === 6) {
      if (data.default_episode && data.default_episode.id) {
        const linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          const linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
          adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  const linkPlayDetail = resultTrailer.play_info && resultTrailer.play_info.data && resultTrailer.play_info.data.hls_link_play
                  adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    } else {
      if (data.default_episode && data.default_episode.id) {
        const linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
        adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
      } else {
        if (data.play_info && data.play_info.data && data.play_info.data.hls_link_play) {
          const linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
          adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
        } else {
          const trailerListTemp = data.trailers
          if (Array.isArray(trailerListTemp) && trailerListTemp.length > 0) {
            const fetchDetailTrailer = async () => {
              try {
                const resultTrailer = await getData('GET', `get_detail/${trailerListTemp[0]?.id}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
                if (resultTrailer) {
                  const linkPlayDetail = resultTrailer.play_info && resultTrailer.play_info.data && resultTrailer.play_info.data.hls_link_play
                  adtagParamStr = handleParamAdtagUrlLinkPlay(linkPlayDetail, data?.metadata)
                }
              } catch (err) {
                console.log(err)
              }
            }

            fetchDetailTrailer()
          }
        }
      }
    }

    return adtagParamStr
  }

  useEffect(() => {
    if (player) {
      // let linkPlayDetail = ''

      // if (data.type === 6) {
      //   if (data.default_episode && data.default_episode.id) {
      //     setTitle(data.episode_name)
      //     linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
      //   } else {
      //     setTitle(data.episode_name)
      //     linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
      //   }
      // } else {
      //   if (data.default_episode && data.default_episode.id) {
      //     setTitle(data.default_episode.title)
      //     linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
      //   } else {
      //     setTitle(data.title)
      //     linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
      //   }
      // }

      if (data.disable_ads !== 0) {
        handleLinkPlayWithTrailer()
      } else {
        callApiAds(data.id)
          .then((result) => {
          // let linkPlayDetail = ''
          // if (data.default_episode && data.default_episode.id) {
          //   setTitle(data.default_episode.title)
          //   linkPlayDetail = data.default_episode.play_info && data.default_episode.play_info.data && data.default_episode.play_info.data.hls_link_play
          // } else {
          //   setTitle(data.title)
          //   linkPlayDetail = data.play_info && data.play_info.data && data.play_info.data.hls_link_play
          // }

            const xw = new XMLWriter(true)
            xw.startDocument('1.0', 'UTF-8')
            xw.startElement('vmap:VMAP')
            xw.writeAttribute('xmlns:vmap', 'http://www.iab.net/videosuite/vmap')
            xw.writeAttribute('version', '1.0')

            const adsResData = result.data.data
            // eslint-disable-next-line array-callback-return
            adsResData.map((adsItem, index) => {
              let type = ''

              if (adsItem.type === 'PREROLL') {
                const adsItemUrl = adsItem.url
                const urlReplace = typeof adsItemUrl === 'string' ? adsItemUrl.replace('&url=[REFERRER_URL]', '') : ''
                const urlReplaceTemp = urlReplace.replace('&correlator=[TIMESTAMP]', `&correlator=${Date.now()}`)

                const paramAdtagTemp = handleParamAdtagUrl()
                const urlParamTemp = urlReplaceTemp + '&' + paramAdtagTemp

                type = 'preroll'
                xw.startElement('vmap:AdBreak')
                xw.writeAttribute('timeOffset', 'start')
                xw.writeAttribute('breakType', 'linear')
                xw.writeAttribute('breakId', type)

                xw.startElement('vmap:AdSource')
                xw.writeAttribute('id', `${type}-ad-${index + 1}`)
                xw.writeAttribute('allowMultipleAds', 'false')
                xw.writeAttribute('followRedirects', 'true')

                xw.startElement('vmap:AdTagURI')
                xw.writeAttribute('templateType', 'vast3')
                // xw.writeCData(adsItem.url)
                xw.writeCData(urlParamTemp)

                xw.endElement()
                xw.endElement()
                xw.endElement()
              } else if (adsItem.type === 'MIDROLL') {
                const adsItemUrl = adsItem.url
                const urlReplace = typeof adsItemUrl === 'string' ? adsItemUrl.replace('&url=[REFERRER_URL]', '') : ''
                const urlReplaceTemp = urlReplace.replace('&correlator=[TIMESTAMP]', `&correlator=${Date.now()}`)

                const paramAdtagTemp = handleParamAdtagUrl()
                const urlParamTemp = urlReplaceTemp + '&' + paramAdtagTemp

                type = 'midroll-1'
                xw.startElement('vmap:AdBreak')
                xw.writeAttribute('timeOffset', `00:${adsItem.time}:00.000`)
                xw.writeAttribute('breakType', 'linear')
                xw.writeAttribute('breakId', type)

                xw.startElement('vmap:AdSource')
                xw.writeAttribute('id', `${type}-pre-bumper`)
                xw.writeAttribute('allowMultipleAds', 'false')
                xw.writeAttribute('followRedirects', 'true')

                xw.startElement('vmap:AdTagURI')
                xw.writeAttribute('templateType', 'vast3')
                // xw.writeCData(adsItem.url)
                xw.writeCData(urlParamTemp)
                xw.endElement()
                xw.endElement()

                xw.startElement('vmap:Extensions')
                xw.startElement('vmap:Extension')
                xw.writeAttribute('type', 'bumper')
                xw.writeAttribute('suppress_bumper', 'true')

                xw.endElement()
                xw.endElement()
                xw.endElement()
              } else if (adsItem.type === 'POSTROLL') {
                const adsItemUrl = adsItem.url
                const urlReplace = typeof adsItemUrl === 'string' ? adsItemUrl.replace('&url=[REFERRER_URL]', '') : ''
                const urlReplaceTemp = urlReplace.replace('&correlator=[TIMESTAMP]', `&correlator=${Date.now()}`)

                const paramAdtagTemp = handleParamAdtagUrl()
                const urlParamTemp = urlReplaceTemp + '&' + paramAdtagTemp

                type = 'postroll'
                xw.startElement('vmap:AdBreak')
                xw.writeAttribute('timeOffset', 'end')
                xw.writeAttribute('breakType', 'linear')
                xw.writeAttribute('breakId', type)

                xw.startElement('vmap:AdSource')
                xw.writeAttribute('id', `${type}-pre-bumper`)
                xw.writeAttribute('allowMultipleAds', 'false')
                xw.writeAttribute('followRedirects', 'true')

                xw.startElement('vmap:AdTagURI')
                xw.writeAttribute('templateType', 'vast3')
                // xw.writeCData(adsItem.url)
                xw.writeCData(urlParamTemp)
                xw.endElement()
                xw.endElement()

                xw.startElement('vmap:Extensions')
                xw.startElement('vmap:Extension')
                xw.writeAttribute('type', 'bumper')
                xw.writeAttribute('suppress_bumper', 'true')

                xw.endElement()
                xw.endElement()
                xw.endElement()
              }
            })
            xw.endDocument()

            const overlayAds = document.getElementById('video-overlay-ads')
            if (overlayAds) {
              overlayAds.style.display = 'none'
            }

            player.ima({
              adsResponse: xw.toString()
              // adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
            })
            // setSrc({
            //   type: 'application/x-mpegURL',
            //   src: linkPlayDetail
            // })
            handleLinkPlayWithTrailer()
          })
          .catch((err) => {
          // setSrc({
          //   type: 'application/x-mpegURL',
          //   src: linkPlayDetail
          // })
            handleLinkPlayWithTrailer()
            console.log(err)
          })
      }

      // player.on('ready', () => {
      //   window.scrollTo(0, 0)
      // })
      player.on('loadstart', () => {
        window.scrollTo(0, 0)

        // const promisePlay = player.play()
        // if (promisePlay !== undefined) {
        //   promisePlay.then(function () {
        //     // Autoplay started!
        //   }).catch(function (error) {
        //     console.log(error)
        //   })
        // }
      })
      player.on('loadedmetadata', () => {
        if (progress && progress > 5) {
          player.currentTime(progress)
        }
      })

      // User interactive
      player.on('useractive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'block'

        const ageRatingElement = document.getElementsByClassName('detail-age-rating-wrapper')
        if (ageRatingElement && ageRatingElement[0]) {
          ageRatingElement[0].style.display = 'block'
        }
      })
      player.on('userinactive', (event) => {
        const reportErrorElement = document.getElementsByClassName('report-error-btn')
        reportErrorElement[0].style.display = 'none'

        const ageRatingElement = document.getElementsByClassName('detail-age-rating-wrapper')
        if (ageRatingElement && ageRatingElement[0]) {
          ageRatingElement[0].style.display = 'none'
        }
      })

      // Catch Error
      player.on('error', () => {
        const playerError = player.error()
        if (playerError) {
          if (playerError?.error?.code === 2) {
            sentryException(`[VOD] - ${title}`, {
              link_play: player && player.currentSrc(),
              error: player.error()
            })
          }
        }
      })
    }

    return () => {
      if (player) {
        const currentTime = player.currentTime()
        const currentTimeParse = typeof currentTime === 'number' ? Math.floor(currentTime) : 0

        if (data) {
          const formData = new FormData()
          // formData.append('content_id', (data && data.default_episode) ? data.default_episode.id : data.id)
          formData.append('content_id', (data.default_episode && data.default_episode.id) ? data.default_episode.id : data.id)
          formData.append('progress', currentTimeParse)

          if (currentTimeParse > 5) {
            if (localStorage.getItem('adm_token')) {
              axios.post(`${process.env.REACT_APP_API_URL}progress/`, formData, {
                headers: {
                  Authorization: `${localStorage.getItem('adm_token')}`
                }
              })
                .then(res => {
                  console.log(res)
                })
                .catch(err => {
                  console.log(err)
                })
            }
          }
        }
      }

      dispatch(setProgress(null))
    }
  }, [player])

  useEffect(() => {
    if (data && player) {
      // Trạng thái hiển thị skip intro
      const introTime = (data.default_episode && data.default_episode.intro_time) ? data.default_episode.intro_time : data.intro_time
      const introProgress = (data.default_episode && data.default_episode.progress) ? data.default_episode.progress : data.progress

      if (introTime > 0 && introProgress < introTime) {
        setShowSkipIntro(true)
      } else {
        setShowSkipIntro(false)
      }
    }
  }, [data, player])

  useEffect(() => {
    if (showSkipIntro && data && player) {
      setIntervalSkipIntro(
        setInterval(() => {
          const introTime = (data.default_episode && data.default_episode.intro_time) ? data.default_episode.intro_time : data.intro_time
          const playerCurrentTime = player.currentTime()

          if (playerCurrentTime > introTime) {
            setShowSkipIntro(false)
            clearInterval(intervalSkipIntro)
          }
        }, 1000)
      )
    }
  }, [showSkipIntro, data, player])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModalReportError = () => {
    setIsShowModal(true)
  }

  const handleCloseModalReportError = (value) => {
    setIsShowModal(value)
  }

  const handlePlayerReady = (player) => {
    ref.current = player
    setPlayer(player)
  }

  const handleClickNextVod = (e, item) => {
    // history.push(`/detail/${item.slug}`)

    // history.push(`/detail/${item.slug}`)
    // history.push('/empty')
    // history.goBack()

    window.location.href = `/detail/${item.slug}`
  }

  const handleClickSkipIntro = () => {
    if (player && data) {
      const introTime = (data.default_episode && data.default_episode.intro_time) ? data.default_episode.intro_time : data.intro_time

      player.currentTime(introTime)
      player.play()
      setShowSkipIntro(false)
    }
  }

  const handleClickCancelNextEpisode = () => {
    setIsNextEpisode(false)
    setCounterNextEpisode(10)
    if (intervalNextEpisode) {
      clearInterval(intervalNextEpisode)
    }
  }

  const handleClickNextEpisode = () => {
    // history.push(`/detail/${idNextEpisode}`)

    // history.push(`/detail/${idNextEpisode}`)
    // history.push('/empty')
    // history.goBack()

    window.location.href = `/detail/${idNextEpisode}`
  }

  const handleChangeTitle = (value) => {
    setTitle(value)
  }

  const handleChangeSrc = (value) => {
    setSrc({
      type: 'application/x-mpegURL',
      src: value
    })
  }

  const handleRelateList = (value) => {
    setRelateList(value)
  }

  const handleSeasonList = (value) => {
    setSeasonList(value)
  }

  const handleEpisodeList = (value) => {
    setEpisodeList(value)
  }

  const handleClickOverlayAds = () => {
    const overlayAds = document.getElementById('video-overlay-ads')
    if (overlayAds) {
      overlayAds.style.display = 'none'
    }
    if (player) {
      player.play()
    }
  }

  const handleSkip = () => {
    if (adsManager) {
      adsManager.stop()
      setIsAdPlaying(false)
    }
  }

  const handleHoverSkipAds = () => {
    setIsHoverSkipAd(!isHoverSkipAd)
  }

  return (
    <div className="detail">
      <div className="row pt-4">
        <div className="col-0 col-md-2">
          <DetailAds data={data} position="left" />
        </div>
        <div className="col-12 col-lg-8">
          <div data-vjs-player className="video-container" style={{ position: 'relative' }}>
            {ageRating?.image_url &&
              <div className='detail-age-rating-wrapper'>
                <img
                  className='detail-age-rating-icon'
                  src={ageRating?.image_url}
                // src='https://s3-hcm.cloud.cmctelecom.vn/sctv-main-2/tenants/none_tenant/photos/t13_f5201dc0.png'
                />
              </div>
            }

            <div className="skip-intro-btn">
              {showSkipIntro &&
                <button className="btn btn-primary" onClick={handleClickSkipIntro}>Bỏ qua giới thiệu</button>
              }
            </div>
            <div className="report-error-btn" onClick={handleClickShowModalReportError}>
              <FontAwesomeIcon className="report-icon" icon={faExclamationCircle} />
              <div className="report-text">Báo lỗi</div>
            </div>

            {/* End VOD */}
            {isEndVod &&
              <div className="end-vod-container">
                <div className="end-vod-list">
                  <div className="row">
                    {Array.isArray(relateList) && relateList.map((item, index) => {
                      if (index < 2) {
                        return (
                          <div key={index} className="col-6 ps-5 pe-5" onClick={e => handleClickNextVod(e, item)}>
                            <figure className="figure">
                              {/* <div className="figure-overflow"> */}
                              <img
                                className="rounded-block w-100 img-next-vod"
                                src={item.images
                                  ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                                  : imgholder
                                }
                                onError={(e) => {
                                  e.target.onerror = null
                                  e.target.src = imgholder
                                }}
                              />
                              {/* </div> */}
                            </figure>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>

                <div className="end-vod-text ms-5">Chương trình tiếp theo sẽ bắt đầu sau <b>{counterNextVod} giây</b></div>
              </div>
            }

            {isNextEpisode &&
              <div className="next-episode-container">
                <div className="row">
                  <div className="col-12">
                    <div className="next-episode-text">Tiếp theo: {titleNextEpisode}</div>

                    <div className="countdown" onClick={handleClickNextEpisode}>
                      <svg viewBox="-50 -50 100 100" strokeWidth="10">
                        <circle r="45"></circle>
                        <circle r="45" strokeDasharray="282.7433388230814" strokeDashoffset="282.7433388230814px"></circle>
                      </svg>
                    </div>

                    <div className="next-episode-cancel" onClick={handleClickCancelNextEpisode}>Hủy</div>
                  </div>
                </div>
              </div>
            }

            {options &&
              <Video
                type="vod"
                options={options}
                onReady={handlePlayerReady}
                src={src}
              />
            }

            {/* Overlay Ads Pause */}
            <div className="video-overlay-ads" id="video-overlay-ads" onClick={handleClickOverlayAds}>
              <div className="overlay-in">
                <AdsItem
                  type="pause"
                  adsPos="POS003"
                  adsClassName="desktop-ads"
                />

                <AdsItem
                  type="pause"
                  platform="mobile"
                  adsPos="POS003"
                  adsClassName="mobile-ads"
                />
              </div>
              {/* <div className="overlay-close"></div> */}
            </div>

            {/* Skip Ads */}
            {(isAdPlaying && isCountering && !isSkipableAds) &&
              <div
                style={{
                  position: 'absolute',
                  opacity: 1,
                  bottom: '18px',
                  cursor: 'default',
                  padding: '15px 0 15px 15px',
                  pointerEvents: 'auto',
                  right: 0,
                  zIndex: 1112
                }}
              >
                <div
                  // className="btn btn-primary"
                  style={{
                    minWidth: '120px',
                    opacity: 1,
                    // background: 'rgba(0,0,0,0.8)',
                    backgroundColor: 'black',
                    padding: '10px'
                  }}
                // style={{
                //   position: 'absolute',
                //   bottom: 50,
                //   right: 40,
                //   zIndex: 1112,
                //   backgroundColor: 'black',
                //   color: 'white',
                //   border: 'none',
                //   borderRadius: 0
                // }}
                >
                  <div
                    style={{
                      color: '#e6e6e6',
                      opacity: 1,
                      fontFamily: 'arial,sans-serif',
                      fontWeight: 'normal',
                      fontSize: '11px',
                      lineHeight: '21px',
                      paddingRight: 0,
                      textAlign: 'center',
                      display: 'inline-block',
                      width: '100%',
                      verticalAlign: 'middle'
                    }}
                  >
                    {counterSkipAds}
                  </div>
                </div>
              </div>
            }

            {(isAdPlaying && !isCountering && !isSkipableAds) &&
              <div
                style={{
                  position: 'absolute',
                  opacity: 1,
                  bottom: '18px',
                  cursor: 'default',
                  padding: '15px 0 15px 15px',
                  pointerEvents: 'auto',
                  right: 0,
                  zIndex: 1112,
                  minWidth: '220px'
                }}
              >
                <button
                  style={{
                    opacity: 1,
                    backgroundColor: isHoverSkipAd ? '#4d4d4d' : 'black',
                    border: '1px solid rgba(255,255,255,0.5)',
                    borderRight: 0,
                    boxSizing: 'content-box',
                    color: '#fff',
                    cursor: 'pointer',
                    fontFamily: 'arial,sans-serif',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    textAlign: 'center',
                    lineHeight: 'normal',
                    // minWidth: 0,
                    padding: '15px 70px 15px 100px',
                    // width: 'auto',
                    margin: 0
                  }}
                  // className="btn btn-primary custom-skip-ads-button"
                  // style={{
                  //   position: 'absolute',
                  //   bottom: 50,
                  //   right: 40,
                  //   zIndex: 1112,
                  //   backgroundColor: isHoverSkipAd ? '#4d4d4d' : 'black',
                  //   color: 'white',
                  //   border: 'none',
                  //   borderRadius: 0
                  // }}
                  onClick={handleSkip}
                  onMouseEnter={handleHoverSkipAds}
                  onMouseLeave={handleHoverSkipAds}
                >
                  Skip Ads
                  <FontAwesomeIcon className="ms-2" icon={faStepForward} />
                </button>
              </div>
            }
          </div>

          <DetailInfo data={data} title={title} hashtagList={hashtagList} />

          {/* {(Array.isArray(seasonList) && seasonList.length > 0) &&
            <DetailSeason
              data={data}
              onSeasonList={handleSeasonList}
              onChangeTitle={handleChangeTitle}
              onChangeSrc={handleChangeSrc}
            />
          } */}

          {(data && data.type !== 6) &&
            <DetailSeason
              data={data}
              onSeasonList={handleSeasonList}
              onEpisodeList={handleEpisodeList}
              onChangeTitle={handleChangeTitle}
              onChangeSrc={handleChangeSrc}
            />
          }

          {(data && data.type !== 6) &&
            <DetailRelate data={data} onRelateList={handleRelateList} />
          }
        </div>
        <div className="col-0 col-md-2">
          <div className="banner-ads">
            <DetailAds data={data} position="right" />
          </div>
        </div>
      </div>

      <ModalReportError
        isShow={isShowModal}
        onClose={handleCloseModalReportError}
      />

      {isLoading && <Overlay />}
    </div>
  )
}

export default Detail
