/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ImageHolder from 'features/core/ImageHolder'
import { Play, InfoCircle } from 'iconsax-react'
import icClear from '../styles/images/ic_clear.svg'

const RibbonItemCW = ({ index, handleClickShowModal, personalRibbonList, onPernalRibbonList, item, slug }) => {
  const [currentProgressPercent, setCurrentProgressPercent] = useState(0)

  useEffect(() => {
    if (item?.runtime > 0 && (item?.runtime * 60) >= item?.progress) {
      const elProgBarWidth = document.getElementsByClassName('current-progress-wrapper')
      if (!elProgBarWidth[0]) return
      const progBarWidth = elProgBarWidth[0].offsetWidth
      const per = (item?.progress * progBarWidth) / (item?.runtime * 60)
      setCurrentProgressPercent(Math.min(per, progBarWidth))
    }
  }, [])

  const handleClickClearProgress = () => {
    const itemSlick = document.getElementById(`item-slick-${index}`)
    if (itemSlick) {
      itemSlick.classList.add('test')
    }

    const arrTemp = [...personalRibbonList]

    setTimeout(() => {
      if (Array.isArray(arrTemp)) {
        const firstItem = arrTemp[0]
        if (firstItem) {
          const firstItemArr = firstItem.items
          if (Array.isArray(firstItemArr)) {
            firstItemArr.splice(index, 1)

            if (itemSlick) {
              itemSlick.classList.remove('test')
            }
          }
        }
      }

      onPernalRibbonList(arrTemp)
    }, 400)

    const itemId = (item?.default_episode && item?.default_episode.id) ? item.default_episode.id : item?.id
    if (localStorage.getItem('adm_token')) {
      axios.delete(`${process.env.REACT_APP_API_URL}progress-detail/${itemId}/`, {
        headers: {
          Authorization: `${localStorage.getItem('adm_token')}`
        }
      })
        .then(res => {
          axios.get(`${process.env.REACT_APP_API_URL}personal-ribbons/`, {
            headers: {
              Authorization: `${localStorage.getItem('adm_token')}`
            }
          })
            .then(res => {
              // const resData = res.data
              // if (Array.isArray(resData)) {
              //   onPernalRibbonList(resData)
              // }
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <div key={index} id={`item-slick-${index}`} className="pb-1 pb-md-0 item-figure">
      {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
        <figure className="figure">
          <div className="delete-cw-icon" onClick={handleClickClearProgress}>
            <img src={icClear} width={10} height={10} alt="" />
          </div>

          <div className="figure-overflow">
            <Link to={`${item.type === 5 ? '/live/' : (item.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/dang-xem/`)}${(item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug}`} onClick={e => handleClickShowModal(e, item.progress)}>
              <ImageHolder data={item.images} />
            </Link>
          </div>
          {item.type !== 5 &&
            <div className="info-detail" onClick={e => handleClickShowModal(e, item)}>
              <Link to={`${item.type === 5 ? '/live/' : (item.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/dang-xem/`)}${(item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug}`} onClick={e => handleClickShowModal(e, item.progress)}>
                <button className="btn btn-primary btn-play">
                  {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                  <Play color="black" variant='Bold' size={12} className='me-1' />
                  Xem
                </button>
              </Link>
              <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, item)}>
                {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                Thông tin
              </button>
              {/* {directorStr.length > 0 &&
                <div className="info-text">Đạo diễn: {directorStr}</div>
              }
              {actorStr.length > 0 &&
                <div className="info-text">Diễn viên: {actorStr}</div>
              }
              <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
            </div>
          }

          {item?.type === 2
            ? (
                <div className='current-episode-number'>Tập {item?.default_episode?.episode}</div>
              )
            : null}

          {item.type !== 5 &&
            <>
              <div className='current-progress-wrapper'>
                <div className='current-progress' style={{ width: currentProgressPercent }} />
              </div>
              <div className='ribbon-item-watching-overlay' />
            </>
          }
        </figure>
      {/* </Link> */}
    </div>
  )
}

export default RibbonItemCW
